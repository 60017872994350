import React from "react";

function Blog () {

    return(
        <div>
            <p>No posts yet, but I'll have some up soon!</p>
        </div>
    );
};

export default Blog;